import React from 'react';
import Header from '../Pages/header';
import Footer from '../Pages/footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Header/>

      <div className='w-full flex justify-center items-center'>
        <div className="container mx-auto px-[5rem] py-8 bg-gray-50 max-w-[80rem] mt-[10rem] mb-10">
        <h1 className="text-3xl font-bold mb-6">KENECARE User Privacy Policy</h1>
        <p className="text-sm text-gray-600 mb-6">Last Updated: January 20<sup>th</sup>, 2025</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">INTRODUCTION</h2>
          <p className="mb-4">
          The operator of Kenecare website, application and mini-program is IMO Tech Solutions Ltd.,
          registered addresses at 88 Pademba Road Freetown, Sierra Leone and 9470 Annapolis Road,
          Suite 213, Lanham, MD 20706 United States of America, and its affiliate (“we”).
          </p>
          <p className="mb-4">
          In this Privacy Policy, we describe our practices in collecting, using, and providing personal
          information to external parties, and elaborate on your rights in respect to your personal
          information in accordance with applicable laws, regulations and technical standards.
          </p>
          <p className="mb-4">
          This Privacy Policy relates to your use of our products and/or services (collectively, “Our
          Products and/or Services”). When you download, install, launch, browse, register, log in or use
          Our Products and/or Services, we will process and protect your personal information in
          accordance to this Privacy Policy.
          </p>
          <p className="mb-4 font-bold">
          If you are under the age of 18 or do not meet the age requirements for using Kenecare
          platform in the place where you live, or if there are other legal requirements that are not
          compatible with user behavior, please follow the legal requirements, to (A) not use the
          Kenecare platform, or (B) read this Privacy Policy under the supervision and guidance of
          your legal guardian (“Guardian”), and only use the KENECARE platform after ensuring
          that your Guardian agrees to the content of this Privacy Policy as required by applicable
          laws.
          </p>
          <p className="mb-4">
          Please read carefully and fully this Privacy Policy before using or continuing to use Our
          Products or Services and, if necessary, make appropriate choices in accordance with the
          guidelines in this Privacy Policy. If you do not provide certain personal information to us, Our
          Products and/or Services may not function properly or achieve the results we intend to provide.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">CONTENTS</h2>
          <ol className="list-decimal list-inside">
          <li className="mb-2">How We Collect and Use Your Personal Information</li>
          <li className="mb-2">How We Share, Transfer and Publicly Disclose Your Personal Information</li>
          <li className="mb-2">How We Store and Protect Your Personal Information</li>
          <li className="mb-2">How You Manage Your Personal Information</li>
          <li className="mb-2">How You Cancel Your Account</li>
          <li className="mb-2">Special Instructions on Products and/or Services Provided by Third Parties</li>
          <li className="mb-2">How We Use Cookie and Other Similar Technologies</li>
          <li className="mb-2">Protection of Minors</li>
          <li className="mb-2">How We Update the Privacy Policy</li>
          <li className="mb-2">How To Contact Us</li>
          <li className="mb-2">Miscellaneous</li>
          <li className="mb-2">Supplemental EEA+ Privacy Statement</li>
          <li className="mb-2">Supplement Virginia, Colorado, Connecticut, Utah, Oregon, Texas, Montana, Delaware, Iowa, Nebraska, New Hampshire, New Jersey or California Privacy Statement</li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">I. How We Collect and Use Your Personal Information</h2>
          <p className="mb-4">
          At Kenecare, our mission is to “empower health and well-being through
          accessible digital healthcare.” Our platform enables users to manage their health
          journey seamlessly by booking virtual consultations, maintaining medical records,
          and accessing personalized health advice. In this Privacy Policy, we outline the
          scope of personal information collected by each feature of our platform, the
          purposes of this collection, the methods used, and the potential consequences of
          withholding your personal information.
          </p>
          <p className="mb-4">
          Please note: While certain features of Kenecare require specific personal
          information to function effectively, you may still access and use other features of
          our app if you choose not to provide the requested information.
          </p>
          <p className="mb-4">
          We will collect and use the personal information you provide voluntarily or that is
          generated during your use of Our Products and/or Services, following the principles of
          legitimacy, legality, and necessity, for the purposes stated in this Privacy Policy. If we
          intend to use your personal information for purposes not specified in this Privacy Policy,
          or use the collected information for other purposes based on specific needs, we will
          inform you in a reasonable manner and obtain your consent again before using it. You can
          view the details through [Settings - Personal Information List].
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-4">1. Account Registration and Login</h3>
          <h4 className="text-lg font-semibold mt-4 mb-2">1.1 Registration Information</h4>
          <p className="mb-4">
          When you click the “Sign up” button to register on Kenecare, we will collect the
          following information from you:
          </p>
          <ul className="list-disc list-inside mb-4">
          <li>Mobile phone number</li>
          <li>User type (e.g., Patient or Doctor)</li>
          <li>Password</li>
          <li>Confirm Password</li>
          </ul>
          <p className="mb-4">
          Your mobile phone number and password are securely stored. We use your
          mobile phone number to send a One-Time Password for verification, ensuring
          that the mobile phone number belongs to you.
          </p>

          <h4 className="text-lg font-semibold mt-4 mb-2">1.2 OTP Verification</h4>
          <p className="mb-4">
          After submitting your registration details, you will receive an OTP via the mobile
          phone number provided. To complete the verification:
          </p>
          <p className="mb-4">
          Enter the OTP on the OTP Verification Page. If the OTP is valid, you will proceed to the next screen based on the user type you selected during registration.
          </p>

          <h4 className="text-lg font-semibold mt-4 mb-2">1.3 Additional Information for Patients</h4>
          <p className="mb-4">
          If you register as a Patient, you will be prompted to provide further details:
          </p>
          <h5 className="font-semibold mt-2 mb-2">1. Personal Information (Required)</h5>
          <ul className="list-disc list-inside mb-4">
          <li>First Name</li>
          <li>Middle Name</li>
          <li>Last Name</li>
          <li>Gender</li>
          <li>Date of Birth</li>
          </ul>
          <h5 className="font-semibold mt-2 mb-2">2. Medical History Information (Optional)</h5>
          <ul className="list-disc list-inside mb-4">
          <li>Weight</li>
          <li>Height</li>
          <li>Allergies</li>
          <li>Disability status and description (if applicable)</li>
          <li>Tobacco usage status and frequency (if applicable)</li>
          <li>Caffeine usage status and frequency (if applicable)</li>
          <li>Alcohol usage status and frequency (if applicable)</li>
          </ul>

          <h4 className="text-lg font-semibold mt-4 mb-2">1.4 Additional Information for Doctors</h4>
          <p className="mb-4">
          If you register as a Doctor, you will be prompted to provide further details:
          </p>
          <h5 className="font-semibold mt-2 mb-2">3. Personal Information (Required)</h5>
          <ul className="list-disc list-inside mb-4">
          <li>First Name</li>
          <li>Middle Name</li>
          <li>Last Name</li>
          <li>Gender</li>
          <li>Date of Birth</li>
          </ul>
          <h5 className="font-semibold mt-2 mb-2">4. Medical History Information (Optional)</h5>
          <ul className="list-disc list-inside mb-4">
          <li>Weight</li>
          <li>Height</li>
          <li>Allergies</li>
          <li>Disability status and description (if applicable)</li>
          <li>Tobacco usage status and frequency (if applicable)</li>
          <li>Caffeine usage status and frequency (if applicable)</li>
          <li>Alcohol usage status and frequency (if applicable)</li>
          </ul>
          <p className="mb-4">
          After completing these steps, you can access the Kenecare platform and begin
          using its features.
          </p>
        </section>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default PrivacyPolicy;
